@import 'styles/import.scss';

.accordionWrapper {
  @include green-underline;
  :local(.title),
  :local(.titleWrapper),
  :local(.titleSubtitle .subtitle) {
    @include tn(color, 0.5s, 0s, ease-in-out);
  }

  &:hover {
    :local(.icon) {
      &::after,
      &::before {
        background-color: $green;
      }
    }
    :local(.title),
    :local(.titleWrapper),
    :local(.titleSubtitle .subtitle) {
      color: $green;
    }
  }
}

.titleWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
  position: relative;
  &.isOpen {
    :local(.icon) {
      @include accordion-plus-icon-open;
    }
  }
}

.info {
  @include text-md;
  padding-bottom: 24px;
  margin-top: 8px;
}

.title {
  @include text-xl;
}

.kontoTitle {
  @include text-lg;
}

.data {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.right {
  padding-bottom: 24px;

  @include media-breakpoint-up(sm) {
    @include make-col-offset(2);
    padding-bottom: 0;
  }
}

.dates {
}

.details {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}

.titleSubtitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.konto {
  display: flex;
  flex-direction: column;
  margin-right: 48px;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-right: 48px;
    align-items: center;
    gap: 32px;
    :local(.titleSubtitle),
    :local(.titleOnly) {
      width: 400px;
    }
  }
  @include media-breakpoint-up(lg) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    :local(.titleSubtitle),
    :local(.titleOnly) {
      width: initial;
    }
  }
  @include media-breakpoint-up(xl) {
    flex-direction: row;
    margin-right: 48px;
    align-items: center;
    gap: 32px;
    :local(.titleSubtitle),
    :local(.titleOnly) {
      width: 456px;
    }
  }
  @include media-breakpoint-up(xxl) {
    :local(.titleSubtitle),
    :local(.titleOnly) {
      width: 480px;
    }
  }
}

.moodle {
  svg {
    margin-left: 24px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: auto;
    margin-top: 29px;
  }
}

.subtitle {
  @include text-xs;
  font-family: $font-mono;
  color: $grey200;
}

.text {
  @include text-md;
  padding-bottom: 48px;
  @include formatted-li;

  div a {
    color: $black;
    @include gradient-underline;
    &:hover {
      color: $green;
    }
  }

  h6 {
    @include text-lg;
    margin-bottom: 16px;
  }

  table {
    width: 100%;
    margin-bottom: 24px;

    + table {
      margin-bottom: 32px;
    }

    tr {
      margin-bottom: 12px;
      &:first-child {
        margin-bottom: 24px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul,
    li {
      margin-bottom: 0;
    }
    h6 {
      margin-bottom: 0;
    }
    tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    td {
      &:nth-child(2) {
        color: $grey200;
      }
    }
  }
  &.isTimeTable {
    table {
      margin-bottom: 28px;

      tr {
        display: block;
        justify-content: flex-start;
      }
      td {
        display: block;
        margin-bottom: 12px;
        &:nth-child(2) {
          color: $black;
        }
      }
      li {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    &.isTimeTable {
      table {
        tr {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 0;
          align-items: flex-start;
        }
        td {
          display: inline-block;
          margin-bottom: 12px;
          &:first-child {
            min-width: 104px;
            margin-right: 24px;
          }
        }
        li {
          margin-bottom: 12px;
        }
      }
    }
  }
}

.icon {
  @include accordion-plus-icon;
}

.downloadInvoice {
  width: 100%;

  span {
    cursor: pointer;
    color: $black;
    @include gradient-underline;
    &:hover {
      color: $green;
    }
  }
}
